import React, { useState, useEffect } from "react";
import { getDataSources } from "@website-builder/services";
import { buildMentorProfiles } from "./transform";
import { MentorCardList } from "@website-builder/ui/shared/components";
import { MS_DEFAULT_TEMPLATE_SPACE_ID } from "../../../../../utilities/src/constants/storyblok";

const AutomatedMentorCards = ({
	heading = "",
	description = {},
	mentorCount = 4,
	list = [],
	selectedCourses = [],
}) => {
	const [reusableMentorList, setReusableMentorList] = useState([]);

	useEffect(() => {
		if (process.env.GATSBY_TEMPLATE === "PREVIEW") {
			getDataSources(MS_DEFAULT_TEMPLATE_SPACE_ID, "mentor-profiles", true)
				.then((res) => {
					let newList = buildMentorProfiles(res, selectedCourses, mentorCount);
					setReusableMentorList(newList);
				})
				.catch((error) => console.error("Error fetching mentor data:", error));
		} else {
			setReusableMentorList(list);
		}
	}, [list, mentorCount, selectedCourses]);

	return (
		<MentorCardList
			title={heading}
			description={description}
			mentorCardItems={reusableMentorList}
		></MentorCardList>
	);
};

export default AutomatedMentorCards;
